<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-primary my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>

        <b-form v-else
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="title"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`title.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model="form.title[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col cols="6">

              <validation-provider
                v-slot="{ errors }"
                vid="flag"
                :name="$t('labels.labels')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.labels')"
                >
                  <v-select
                    v-model="form.type"
                    :dir="$store.state.appConfig.layout.direction"
                    label="label"
                    :options="types"
                    :reduce="item => item.value"
                    :placeholder="$t('labels.types')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>



            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="description"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`description.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-textarea
                        v-model="form.description[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rows="4"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="image"
                :name="$t('labels.image')"
                rules="required"
              >
                <ImagePreview
                  v-model="image"
                  :label="$t('labels.image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
            <b-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="icon"
                :name="$t('labels.icon')"
                rules="required"
              >
                <ImagePreview
                  v-model="icon"
                  :label="$t('labels.icon')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>


            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0">
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded:false,
      periods: [],
      types: [],

      config: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
      },
      image: null,
      icon: null,
      start_time: null,
      end_time: null,


      form: {
        title: {
          en: null,
          ar: null,
        },
        start_time: null,
        type: null,
        end_time: null,
        description: {
          en: null,
          ar: null,
        },
        status: 'active',
        image: null,
        icon: null,
      },
    }
  },

  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
    icon(val) {
      if (val) {
        this.form.icon = val
      } else {
        this.form.icon = 'delete'
      }
    },


  },
  created() {
    this.getPeriods()
    this.getTypesForPeriod()

    if (this.isEdit) {
      this.loadData()
    }else {
      this.isDataLoaded = true
    }
  },
  methods: {
    getPeriods() {
      this.axios.get('/periods')
        .then(res => {
          this.periods = res.data
        })
    },
    getTypesForPeriod() {
      this.axios.get('lists/prize/types')
        .then(res => {
          this.types = res.data
        })
    },

    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.title,
            description: data.description,
            start_time: data.start_time,
            end_time: data.end_time,
            status: data.status,
            type: data.type,

          }
          this.icon = data.icon
          this.image = data.image

          this.start_time = this.form.start_time.substring(this.form.start_time.indexOf(' ') + 1)
          this.end_time = this.form.end_time.substring(this.form.end_time.indexOf(' ') + 1)


        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if($form.start_time){
        $form.start_time = $form.start_time + ' ' + this.start_time
      }
      if($form.end_time){
        $form.end_time = $form.end_time + ' ' + this.end_time
      }
      if (!($form.image instanceof File)) {
        delete $form.image
      }
      if (!($form.icon instanceof File)) {
        delete $form.icon
      }
      return $form
    },
  },

}
</script>
